<script setup lang="ts">
import type { TranslateResult } from "vue-i18n";
import { mdiArrowRight } from "@mdi/js";
import type { RouteLocationRaw } from "vue-router";
import { useEventBus } from "@vueuse/core";
import { actionMenuKey, MenuType } from "../../types/menuActionKey";

const props = withDefaults(
  defineProps<{
    cardTitle?: string | TranslateResult;
    cardDescription?: string | TranslateResult;
    imagePath?: string;
    imageColor?: string;
    to?: RouteLocationRaw;
    externalLink?: boolean;
    imageClasses?: string[];
    cardClasses?: string[];
  }>(),
  {
    cardDescription: "",
    cardTitle: "",
    imagePath: "",
    imageColor: "rgba(var(--v-theme-primary-lighten-2))",
    imageClasses: () => ["hw-hexagon"],
    cardClasses: () => [],
  },
);

const { isActive } = useActiveRoute(props.to);

const cardClassesComputed = computed(() => {
  return isActive.value
    ? ["desktop-navigation__card--active", ...props.cardClasses]
    : [...props.cardClasses];
});

const actionMenuBus = useEventBus(actionMenuKey);
</script>

<template>
  <v-card
    class="desktop-navigation__card"
    :to="to"
    color="#FFFFFF"
    :class="cardClassesComputed"
    exact
    link
    @click="actionMenuBus.emit({ menuType: MenuType.Desktop })"
  >
    <slot />

    <div class="desktop-navigation__card__content">
      <div class="text-h3 text-center">{{ cardTitle }}</div>

      <div class="d-flex justify-center align-center" style="flex-grow: 1">
        <slot name="image">
          <HwImage
            object-fit="fill"
            :style="{
              backgroundColor: imageColor,
            }"
            :src="imagePath"
            class="desktop-navigation__card__image"
            :class="imageClasses"
          />
        </slot>
      </div>

      <div class="desktop-navigation__card__description">
        <span class="desktop-navigation__card__description--clamp">
          {{ cardDescription }}
        </span>

        <v-icon
          :icon="[mdiArrowRight]"
          class="navigation__card__description__icon"
          :class="{
            'icon--external-link': externalLink,
          }"
        />
      </div>
    </div>
  </v-card>
</template>
